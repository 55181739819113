<template>
  <div class="advertising">
    <div class="card-box">
      <div class="card-item" style="width: 70%; margin-right: 20px; align-items: center">
        <div style="font-size: 20px">我的广告</div>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">创建新广告</el-button>
      </div>
      <div class="card-item" style="width: 30%">
        <div>
          <div style="padding: 10px 0 16px">广告金额</div>
          <div>RM {{ tableData.advert_money }}</div>
        </div>
        <div>
          <el-button @click="toPay" size="small"
            style="min-width: 80px; color: #ee4d2d; border-color: #ee4d2d">充值</el-button>
        </div>
      </div>
    </div>
    <div class="card-list">
      <div class="title">全球广告列表</div>

      <div style="
          margin-top: 20px;
          background: #fafafa;
          border: 1px solid #eee;
          border-radius: 4px;
          padding: 10px;
          font-size: 16px;
        ">
        <span>已选<span style="color: #ee4d2d">{{ multipleSelection.length }}</span>支广告</span>
        <el-divider direction="vertical"></el-divider>
        <el-select @change="handleChange" size="small" style="width: 120px" v-model="value" placeholder="更改状态">
          <el-option label="停止" :value="0"> </el-option>
          <el-option label="立即开始" :value="1"> </el-option>
        </el-select>
        <!-- <el-button style="margin-left: 10px" size="small">更改预算</el-button> -->
      </div>
      <div style="border: 1px solid #eee; margin-top: 20px; border-radius: 4px">
        <el-table :header-cell-style="{ background: '#FAFAFA', 'border-color': '#eee' }" :data="tableData.list"
          style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="views" label="全部" width="280">
            <template slot-scope="scope">
              <div style="display: flex">
                <el-image style="
                    min-width: 60px;
                    width: 60px;
                    height: 60px;
                    margin-right: 10px;
                  " :src="scope.row.image" fit="fit"></el-image>
                <div>
                  <el-tag size="mini" type="success" v-if="scope.row.status == 1">进行中</el-tag>
                  <el-tag v-else size="mini" type="danger">已停止</el-tag>
                  <div style="font-size: 14px; color: #000; width: 180px" class="single-hidden">
                    {{ scope.row.title?scope.row.title:scope.row.name }}
                  </div>
                  <div>
                    {{
                    scope.row.length_time == 0
                    ? "不限时"
                    : scope.row.start_time + "-" + scope.row.end_time
                    }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="views" label="全部">
            <template slot-scope="scope">
              <div v-if="scope">
                <div>搜索</div>
                <div>{{scope.row.promotion_type==0?'店铺':'商品'}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="views" label="预算">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.budget_limit == 0">无限制</div>
                <div v-else>
                  <div>
                    {{ scope.row.budget_type == 0 ? "每日预算" : "总预算" }}
                  </div>
                  <div>RM {{ scope.row.budget }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="views" label="浏览数"> </el-table-column>
          <el-table-column prop="click" label="点击数"> </el-table-column>
          <el-table-column prop="click_rate" label="点击率">
            <template slot-scope="scope">
              <div>{{ parseInt(scope.row.click_rate) }}%</div>
            </template>
          </el-table-column>
          <el-table-column prop="spend" label="花费">
            <template slot-scope="scope">
              <div>RM {{ scope.row.spend }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="sales_amount" label="销售金额">
            <template slot-scope="scope">
              <div>RM {{ scope.row.sales_amount }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="display: flex; justify-content: right; margin-top: 20px">
        <el-pagination :page-size="page_size" :current-page="page" layout="prev, pager, next,sizes"
          :page-sizes="[10, 20, 30, 40, 50]" :total="tableData.total" @current-change="handlePage"
          @size-change="handlePageSize">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import { getAdvertisementList, upAdvertisementState } from "@/api/marketing.js";
  export default {
    data() {
      return {
        tableData: {
          list: [],
        },
        value: "",
        multipleSelection: [],
        page: 1,
        page_size: 10,
      };
    },
    created() {
      this.getList();
    },
    methods: {
      handleAdd() {
        this.$router.push("addAdvertising");
      },
      getList() {
        getAdvertisementList({
          page: this.page,
          page_size: this.page_size,
        }).then((res) => {
          if (res.code == 1) {
            this.tableData = res.data;
            this.tableData.list.forEach((item) => {
              if (item.length_time == 1) {
                item.start_time = this.formatTimeA(item.start_time * 1000, 3);
                item.end_time = this.formatTimeA(item.end_time * 1000, 3);
              }
            });
            sessionStorage.ADVERT_MONEY = res.data.advert_money;
          }
        });
      },
      toPay() {
        this.$router.push("topUp");
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 更改广告状态
      handleChange() {
        if (this.multipleSelection.length == 0) {
          return false;
        }
        let arr = [];
        this.multipleSelection.forEach((item) => {
          arr.push(item.id);
        });

        upAdvertisementState({
          advert_id: arr.join(","),
          status: this.value,
        }).then((res) => {
          if (res.code == 1) {
            this.tableData.list.forEach((item, i) => {
              if (arr.indexOf(item.id) != -1) {
                item.status = this.value;
                this.$set(this.tableData.list, i, item);
              }
            });
          }
        });
      },
      handlePage(page) {
        this.page = page;
        this.getList();
      },
      handlePageSize(size) {
        this.page_size = size;
        this.getList();
      },
    },
  };
</script>
<style lang="less" scoped>
  .advertising {
    width: 1400px;
    margin: 0 auto;
    padding-top: 20px;

    .card-box {
      display: flex;

      .card-item {
        height: 120px;
        padding: 20px 20px;
        background: #fff;
        border-radius: 4px;
        display: flex;

        justify-content: space-between;
      }
    }

    .card-list {
      background: #fff;
      border-radius: 4px;
      padding: 20px;
      margin-top: 20px;
    }
  }
</style>